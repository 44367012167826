import React, { useContext, useEffect, useState } from 'react'
import ProgressIndicator from '@veneer/core/dist/scripts/progress_indicator'
import { MfeComponentContext } from './types'
import * as S from './styles'

export const ComponentLoader = () => {
  const { name, props } = useContext(MfeComponentContext)
  const [component, setComponent] = useState<React.ReactElement | null>(null)

  useEffect(() => {
    if (component === null) {
      ;(async () => {
        const properties = props || {}
        switch (name) {
          case 'react-billing': {
            const { BillingForm } = await import(
              /* webpackChunkName: "jarvis-react-billing" */ `@jarvis/react-billing`
            )
            setComponent(<BillingForm {...properties} />)
            break
          }
          case 'react-shipping': {
            const { ShippingForm } = await import(
              /* webpackChunkName: "jarvis-react-shipping" */ '@jarvis/react-shipping'
            )
            setComponent(<ShippingForm {...properties} />)
            break
          }
          case 'react-instant-ink-plans': {
            const {
              PlanSelector,
              ConsumerPlanSelector,
              PlanSelectorV3
            } = await import(
              /* webpackChunkName: "jarvis-react-instant-ink-plans" */ '@jarvis/react-instant-ink-plans'
            )
            if (properties.componentName === 'ConsumerPlanSelector') {
              setComponent(<ConsumerPlanSelector {...properties} />)
            } else if (properties.componentName === 'PlanSelectorV3') {
              setComponent(<PlanSelectorV3 {...properties} />)
            } else {
              setComponent(<PlanSelector {...properties} />)
            }
            break
          }
          case 'react-manual-printer-claim': {
            const { ManualPrinterClaim } = await import(
              /* webpackChunkName: "jarvis-react-manual-printer-claim" */ '@jarvis/react-manual-printer-claim'
            )
            setComponent(<ManualPrinterClaim {...properties} />)
            break
          }
          default: {
            setComponent(<div>Invalid Component</div>)
            break
          }
        }
      })()
    }
  }, [component, name, props])

  if (component !== null) {
    return component
  }

  return (
    <S.LoadingContainer>
      <ProgressIndicator appearance="linear" />
    </S.LoadingContainer>
  )
}
