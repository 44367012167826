import React, { useEffect, useMemo, useState } from 'react'
import { Switch, Route, useHistory, useLocation } from 'react-router-dom'
import {
  useDispatch,
  useFlowContext,
  useFlowManager,
  usePartialSubscription,
  useSignupState
} from '../../../hooks'
import { StepNames } from '../../../types'
import { updateSignupSession } from '../../../actions'
import { ErrorPage } from '../ErrorPage'

const { START_STEP } = StepNames

export const FlowManagerRoutes = () => {
  const history = useHistory()
  const location = useLocation()
  const flowContext = useFlowContext()
  const flowManager = useFlowManager()
  const dispatch = useDispatch()
  const signupState = useSignupState()
  const partialSubscriptionState = usePartialSubscription()
  const { currentStep, basePath } = signupState.signupSession
  const [isFinished, finalStepPath] = useMemo(
    () => [
      flowManager.isFinished(signupState),
      flowManager.finalPath(signupState)
    ],
    [flowManager, signupState]
  )
  const [ready, setReady] = useState(false)

  useEffect(() => {
    if (!ready) {
      if (currentStep) {
        if (partialSubscriptionState.data) {
          history.replace(isFinished ? finalStepPath : currentStep)
          setReady(true)
        }
      } else {
        history.replace({
          pathname: flowManager.pathFromStep(START_STEP),
          search: location.search
        })
        setReady(true)
      }
    }
  }, [
    ready,
    currentStep,
    history,
    flowManager,
    partialSubscriptionState.data,
    location.search,
    isFinished,
    finalStepPath
  ])

  useEffect(() => {
    if (
      ready &&
      isFinished &&
      location.pathname.startsWith(basePath) &&
      location.pathname !== finalStepPath
    ) {
      setTimeout(() => history.replace(finalStepPath), 0)
    }
  }, [ready, location.pathname, isFinished, history, finalStepPath, basePath])

  useEffect(() => {
    if (ready && location.pathname !== basePath) {
      ;(async () => {
        await dispatch(
          updateSignupSession({
            currentStep: location.pathname
          })
        )
      })()
    }
  }, [ready, location.pathname, basePath, dispatch])

  if (!ready) {
    if (partialSubscriptionState.error) {
      return <ErrorPage stateErrors={[partialSubscriptionState]} />
    }
    return null
  }

  const { Container } = flowContext.getComponents()
  return (
    <Switch>
      <Container>
        {Object.values(flowManager.getSteps()).map(
          ({ key, path, component: Component, props = {} }) =>
            path &&
            Component && (
              <Route key={key} path={path} exact>
                <Component props={props} />
              </Route>
            )
        )}
      </Container>
    </Switch>
  )
}
