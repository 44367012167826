import { FlowType } from '../../types'

export const flowFactory = async ({
  flow,
  basePath
}: {
  flow: FlowType
  basePath: string
}) => {
  switch (flow) {
    case FlowType.PREENROLL: {
      const { V3Flow } = await import(
        /* webpackChunkName: "v3_flow" */ './v3Flow'
      )
      return new V3Flow({ basePath })
    }
    case FlowType.HPID: {
      const { HpidFlow } = await import(
        /* webpackChunkName: "hpid_flow" */ './hpidFlow'
      )
      return new HpidFlow({ basePath })
    }
    case FlowType.OOBE_TEST_SETUP: {
      const { OobeTestSetupFlow } = await import(
        /* webpackChunkName: "oobe_test_setup_flow" */ './oobeTestSetupFlow'
      )
      return new OobeTestSetupFlow({ basePath })
    }
    case FlowType.OOBE_VALUE_PROP: {
      const { OobeValuePropFlow } = await import(
        /* webpackChunkName: "oobe_value_prop_flow" */ './oobeValuePropFlow'
      )
      return new OobeValuePropFlow({ basePath })
    }
    case FlowType.OOBE_ENROLL: {
      const { OobeFlow } = await import(
        /* webpackChunkName: "oobe_flow" */ './oobeFlow'
      )
      return new OobeFlow({ basePath })
    }
    case FlowType.OOBE_EXIT: {
      const { OobeExitFlow } = await import(
        /* webpackChunkName: "oobe_exit_flow" */ './oobeExitFlow'
      )
      return new OobeExitFlow({ basePath })
    }
    case FlowType.FTM: {
      const { FlipConversionFlow } = await import(
        /* webpackChunkName: "ftm_flow" */ './flipConversionFlow'
      )
      return new FlipConversionFlow({ basePath })
    }
    case FlowType.SMB:
    default: {
      const { SmbFlow } = await import(
        /* webpackChunkName: "smb_flow" */ './smbFlow'
      )
      return new SmbFlow({ basePath })
    }
  }
}
