import { ColorSemantics } from '@veneer/semantics'
import primitives from '@veneer/primitives'

export const hpxThemeVariables = (color: ColorSemantics) => ({
  cardBoxShadow: 'none',
  checkmarkIconColor: color.primary.base.default,
  valuePropPadding: '0',
  valuePropPaddingTop: '0',
  enrollStepSpacing: '0',
  enrollCardsMargin: '12px 0 0 0',
  enrollButtonsMarginTop: '64px',
  modalPadding: '40px 24px 24px',
  modalPaddingMobile: '40px 16px 16px',
  modalTitleMarginRight: '30px',
  thankYouMargin: '0',
  exitCardBoxShadow: 'none'
})

export const defaultThemeVariables = {
  cardBoxShadow: '0 4px 16px #21212119',
  checkmarkIconColor: 'black',
  valuePropPadding: '0 16px',
  valuePropPaddingTop: '40px',
  enrollStepSpacing: '8px',
  enrollCardsMargin: '10px 0',
  enrollButtonsMarginTop: '30px',
  modalPadding: '40px 48px',
  modalPaddingMobile: '40px 48px',
  modalTitleMarginRight: '0',
  thankYouMargin: '0 16px',
  exitCardBoxShadow: `0 4px 16px ${primitives.color.gray3}`
}
