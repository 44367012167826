import React, { FC, useCallback } from 'react'
import { usePartialSubscription } from '../../../../stateData/partialSubscription'
import { useSettings } from '../../../../stateData/settings'
import { isStateEmpty } from '../../../../lib/stateHelpers'
import { LoadingPage } from '../../../shared/LoadingPage'
import { Button } from '../../../shared/Button'
import {
  useAnalytics,
  useExitFlow,
  useGetText,
  useSignupSession,
  useUserLocale
} from '../../../../hooks'
import { openChatbot } from './chatbot'
import { MfeComponent } from '../../../../infrastructure/MfeComponent'

type ManualPrinterClaimProps = {
  claimCallback: (params: {
    code: string
    confirm_transfer: boolean
  }) => Promise<void>
}

export const ManualPrinterClaim: FC<ManualPrinterClaimProps> = ({
  claimCallback
}) => {
  const partialSubscriptionState = usePartialSubscription()
  const settingsState = useSettings()
  const exitOobe = useExitFlow()
  const getText = useGetText()
  const { publishCustomEvent } = useAnalytics()
  const { basePath } = useSignupSession()
  const { country, language } = useUserLocale()

  const onClaimClick = useCallback(
    // isPrinterGifting needs manual verfication
    async (claimCode: string, isPrinterGifting = false) => {
      await claimCallback({
        code: claimCode,
        confirm_transfer: isPrinterGifting
      })
    },
    [claimCallback]
  )

  const APIStates = [partialSubscriptionState, settingsState]
  if (isStateEmpty(APIStates)) {
    return <LoadingPage />
  }

  const {
    instructionSet,
    enableJarvisMpcLegacyClaimStep
  } = partialSubscriptionState.data
  const { showSupportPhone, enablePresalesChatbot } = settingsState.data

  const onError = (message: string) => {
    publishCustomEvent({
      event: 'e_formError',
      formName: 'claimConfirmation',
      formError: message
    })
  }

  const skipButton = (
    <Button
      data-analyticsid="SkipOfferLink"
      appearance="ghost"
      onClick={() => exitOobe('declined', {})}
    >
      {getText('connect_step.buttons.skip_instant_ink')}
    </Button>
  )

  return (
    <MfeComponent
      name="react-manual-printer-claim"
      props={{
        onClaimClick: onClaimClick,
        onJarvisError: onError,
        flowName: 'woobe',
        instructionSet: instructionSet,
        language: language,
        country: country,
        virtual_agent_support: enablePresalesChatbot ? openChatbot : undefined,
        call_support: showSupportPhone
          ? getText('navbar.navigation_links.support_phone')
          : undefined,
        base_path: basePath,
        trackClickEvent: () => null,
        skipButton: skipButton,
        enableLegacy: enableJarvisMpcLegacyClaimStep
      }}
    />
  )
}
