import React, { useState, useEffect, useMemo, FC } from 'react'
import { UserLocaleContext } from '../../../contexts'
import { UserLocale } from '../../../types'
import {
  useSignupState,
  useUserAccount,
  useTranslation,
  useSignupSession
} from '../../../hooks'
import { getUserLocale } from '../../../lib/localeHelpers'
import { LoadingPage } from '../LoadingPage'
import { ErrorPage } from '../ErrorPage'

export const UserLocaleProvider: FC = ({ children }) => {
  const userAccount = useUserAccount()
  const { localization, isLoggedIn } = useSignupState()
  const { flow, showSkeleton } = useSignupSession()
  const translation = useTranslation()
  const [userLocale, setUserLocale] = useState<UserLocale>(null)
  const country = useMemo(
    () =>
      isLoggedIn
        ? userAccount.data?.regionId
        : localization.country?.toUpperCase() || 'US',
    [isLoggedIn, userAccount, localization]
  )
  const language = useMemo(() => localization.language || 'en', [localization])

  useEffect(() => {
    if (userLocale === null && translation && country) {
      const locale = getUserLocale(language, country)
      setUserLocale(locale)
      translation.setLocale(locale.language, locale.country)
    }
  }, [userLocale, translation, country, language, flow, localization])

  if (userLocale) {
    return (
      <UserLocaleContext.Provider value={userLocale}>
        {children}
      </UserLocaleContext.Provider>
    )
  }

  if (userAccount.error) {
    return <ErrorPage stateErrors={[userAccount]} />
  }

  return showSkeleton ? null : <LoadingPage />
}
